import { motion } from 'framer-motion';
import styled from 'styled-components';

export default styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  user-select: none;
  height: 100%;
  width: 100vw;

  background-color: ${({ theme }) => theme.colors.black};
  z-index: 97;
  pointer-events: none;
`;
