import ItemWrapper, {
  MotionItemWrapper,
} from 'components/Menu/MenuContentDesktop/styled/ItemWrapper';
import ItemWrapperHitbox from 'components/Menu/MenuContentDesktop/styled/ItemWrapperHitbox';
import ItemWrapperOverflow from 'components/Menu/MenuContentDesktop/styled/ItemWrapperOverflow';
import MenuVideo from 'components/Menu/MenuContentDesktop/styled/MenuVideo';
import Overflow from 'components/Menu/MenuContentDesktop/styled/Overflow';
import Line from 'components/Menu/MenuItems/CloseButton/styled/Line';
import useMenuVariants from 'components/Menu/MenuItems/useMenuVariants';
import gsap from 'gsap';
import React, { useRef } from 'react';
import { MenuItemType } from 'types/menuItems';
import { easeOutQuad } from 'utils/ease';
import { getCenterPointWithOffset } from 'utils/func';
import MenuItem from '../MenuItem';
import { motion } from 'framer-motion';

type Props = {
  items: Array<MenuItemType>;
  openPage: (route: string) => void;
};

const introElement = {
  hidden: {
    y: '-101%',
    transition: {
      duration: 0,
    },
  },
  open: {
    y: 0,
    transition: {
      duration: 0.4,
      ease: easeOutQuad,
    },
  },
};

const introController = {
  hidden: {
    transition: {
      delayChildren: 1,
    },
  },
  open: {
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.15,
    },
  },
};

const MenuContentDesktop = ({ items, openPage }: Props) => {
  const video = useRef<HTMLVideoElement>(null);
  const overflow = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  const clipAnimation = useRef<gsap.core.Tween>(null);

  const resetClip = () => {
    if (clipAnimation.current) {
      clipAnimation.current.pause(0);
    }
    overflow.current.style.clipPath = 'inset(0 100% 100% 0)';
  };

  const onHover = (e: React.PointerEvent<HTMLDivElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const rect = e.target.getBoundingClientRect();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const index = Number.parseInt(e.target.getAttribute('data-key'));
    video.current.src = items[index].image.url;
    video.current.style.display = 'block';
    const height = video.current.offsetHeight;
    const width = video.current.offsetWidth;
    const videoPos = getCenterPointWithOffset(rect, height, width);
    const pathLeft = innerWidth - (width + videoPos.x);
    const pathTop = innerHeight - (height + videoPos.y);
    const path = `inset(${videoPos.y}px ${pathLeft}px ${pathTop}px ${videoPos.x}px)`;

    overflow.current.style.clipPath = `inset(${
      videoPos.y + 2 * height
    }px ${pathLeft}px ${pathTop - height}px ${videoPos.x}px)`;

    gsap
      .timeline()
      .set(video.current, {
        x: videoPos.x,
        y: videoPos.y + height,
      })
      .to(video.current, {
        y: videoPos.y,
        duration: 1,
        ease: 'expo.out',
        onStart: () => {
          clipAnimation.current = gsap.to(overflow.current, {
            clipPath: path,
            duration: 1,
            ease: 'expo.out',
          });
        },
      });
  };

  const onPointerLeave = () => {
    video.current.style.display = 'none';
    resetClip();
  };

  const variants = useMenuVariants();

  return (
    <>
      <MotionItemWrapper
        variants={introController}
        initial={false}
        animate={variants}
      >
        {items.map((item, index) => (
          <motion.div variants={introElement} key={item.id}>
            <MenuItem key={item.id} index={index} name={item.title} />
          </motion.div>
        ))}
      </MotionItemWrapper>
      <Overflow ref={overflow}>
        <MenuVideo ref={video} loop muted playsInline autoPlay />
        <ItemWrapperOverflow ref={wrapper}>
          {items.map((item, index) => (
            <MenuItem key={item.id} index={index} name={item.title} />
          ))}
        </ItemWrapperOverflow>
      </Overflow>
      <ItemWrapperHitbox>
        {items.map((item, index) => (
          <MenuItem
            key={item.id}
            index={index}
            name={item.title}
            onPointerEnter={onHover}
            onPointerLeave={onPointerLeave}
            onClick={() => openPage(item.route)}
          />
        ))}
      </ItemWrapperHitbox>
    </>
  );
};

export default MenuContentDesktop;
