import MenuButtonWrapper from 'components/Menu/MenuButton/styled/MenuButtonWrapper';
import styled from 'styled-components';

export default styled.span`
  display: block;
  height: 2px;
  transform-origin: right;
  transition: transform 0.25s ease-out;
  width: 100%;

  background-color: ${({ theme, light }) =>
    light ? theme.colors.primary.light : theme.colors.black};

  &:nth-child(2) {
    width: 60%;
  }

  ${MenuButtonWrapper}:hover & {
    &:nth-child(1) {
      transform: scaleX(0.5);
    }

    &:nth-child(2) {
      transform: scaleX(0.48);
    }

    &:nth-child(3) {
      transform: scaleX(0.1);
    }
  }
`;
