import styled from 'styled-components';

export default styled.div`
  &::before,
  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1.5px;
    background-color: black;
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;
