import React from 'react';

const Copy = () => {
  return (
    <svg viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 1H1V23" stroke="black" strokeWidth="2" />
      <rect x="4" y="4" width="16" height="20" stroke="black" strokeWidth="2" />
    </svg>
  );
};

export default Copy;
