import ArrowsWrapper from 'components/FilmPage/AwardsPressSection/styled/Footer/ArrowsWrapper';
import ArrowUp from 'components/Icons/ArrowUp';
import React, { useState } from 'react';
import { useRouter } from 'next/router';

import Arrow from 'components/Icons/Arrow';
import Email from 'components/Icons/Email';
import Instagram from 'components/Icons/Instagram';
import CopyPopup from 'components/CopyPopup';

import { FooterType } from 'types/commons';
import { goToTop } from 'utils/dom';

import theme from 'utils/theme';
import { ACTIONS, getCategory, LABEL, Tracking } from 'utils/tracking';
import { copyToClipboard } from 'utils/copyText';

import {
  Column,
  Copyright,
  EmailLink,
  Host,
  InstagramLink,
  Label,
  ButtonColumn,
  Wrapper,
  ScrollButton,
} from './styled';

export type FooterProps = {
  copy: FooterType;
  sticky?: boolean;
  line?: boolean;
  color?: string;
  jumpTarget?: HTMLDivElement;
};

const Footer = ({
  copy,
  sticky = false,
  line = true,
  color = theme.colors.primary.dark,
  jumpTarget = null,
}: FooterProps) => {
  const { label, copyright, email, instagram } = copy;
  const { pathname } = useRouter();
  const [isEmailPopup, setIsEmailPopup] = useState<boolean>(false);

  const offset = jumpTarget ? '0%' : '40%';
  const variants = {
    open: { opacity: 1, y: '0' },
    closed: { opacity: 0, y: offset },
  };

  const onClick = jumpTarget
    ? () => jumpTarget.scrollTo({ top: 0, behavior: 'smooth' })
    : () => goToTop(jumpTarget, 500);

  const onEmailClick = () => {
    setIsEmailPopup(true);
    setTimeout(() => {
      setIsEmailPopup(false);
    }, 1500);

    copyToClipboard(email);

    Tracking.event({
      category: getCategory(pathname),
      action: ACTIONS.Email,
      label: LABEL.Email,
    });
  };

  return (
    <Host color={color} sticky={sticky}>
      <Wrapper line={line} sticky={sticky}>
        <Column>
          <EmailLink onClick={onEmailClick}>
            <Email />
          </EmailLink>

          <InstagramLink href={instagram} target="_blank">
            <Instagram />
          </InstagramLink>

          <Copyright>{copyright}</Copyright>
        </Column>

        <Label href="https://lepolishbureau.com/" target="_blank">
          {label}
        </Label>
        <CopyPopup
          initial={{ opacity: 0, y: '40%' }}
          animate={isEmailPopup ? 'open' : 'closed'}
          variants={variants}
          sticky={sticky}
          fixed={true}
        />
      </Wrapper>
      {!sticky && (
        <ButtonColumn>
          <ScrollButton
            onClick={onClick}
            whileHover={{ y: '+10%' }}
            whileTap={{ y: '+10%' }}
          >
            <ArrowUp />
          </ScrollButton>
        </ButtonColumn>
      )}
    </Host>
  );
};

export default React.memo(Footer);
