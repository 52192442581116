import styled, { css } from 'styled-components';

import { landscape, mediaDesktop } from 'utils/responsive';
import { Arapey } from 'utils/styles/typography';

export default styled.div`
  ${Arapey};
  font-style: italic;
  font-size: 2.5vh;
  text-align: center;
  pointer-events: none;
  ${landscape} {
    font-size: 2vw;
  }
  ${mediaDesktop(css`
    font-size: 2.5vh;
  `)}
`;
