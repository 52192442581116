import styled, { css } from 'styled-components';
import {
  landscape,
  mediaDesktop,
  mediaTabletAndPortrait,
} from 'utils/responsive';
import {
  MadeBonVoyage,
  MadeBonVoyageThin,
  NexaBlack,
} from 'utils/styles/typography';

export default styled.div`
  ${MadeBonVoyage};
  white-space: pre-line;
  font-size: 9vh;
  line-height: 9vh;
  text-decoration: none;
  pointer-events: none;
  ${landscape} {
    font-size: 7vw;
    line-height: 7vw;
  }
  ${mediaTabletAndPortrait(css`
    font-size: 13vw;
    line-height: 13vw;
  `)};
  ${mediaDesktop(css`
    font-size: 7.5vw;
    line-height: 6vw;
    font-size: max(7.5vw, 100px);
    line-height: max(10.5vw, 80px);
  `)};
  b {
    ${NexaBlack};
    text-transform: uppercase;
  }
  i {
    font-style: normal;
    ${MadeBonVoyageThin};
  }
`;
