import MenuContentDesktop from 'components/Menu/MenuContentDesktop';
import useMenuVariants from 'components/Menu/MenuItems/useMenuVariants';
import { FilmPageContext } from 'context/FilmPageContext';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import { MenuItemType } from 'types/menuItems';
import { easeOutQuad } from 'utils/ease';
import useDesktopMode from 'utils/hooks/useDesktopMode';

import CloseButton from './CloseButton';
import Content from './styled/Content';
import Overlay from './styled/Overlay';
import Wrapper from './styled/Wrapper';
import MenuItem from '../MenuItem';

export type MenuItemsProps = {
  items: Array<MenuItemType>;
};

const menuVariant = {
  open: {
    y: 0,
    transitionEnd: {
      pointerEvents: 'auto',
    },
  },
  hidden: { y: '-100%', pointerEvents: 'none' },
};

const MenuItems = ({ items }: MenuItemsProps) => {
  const { state, setState } = useContext(FilmPageContext);
  const variantOverlay = state.menuOpen ? { opacity: 1 } : { opacity: 0 };
  const { isDesktopMode } = useDesktopMode();
  const variants = useMenuVariants();

  const { push } = useRouter();

  const openPage = route => {
    push(`/${route}`).then(() => {
      setState({
        isOpen: false,
        currentFilm: -1,
        isHovered: -1,
        menuOpen: false,
      });
    });
  };

  return (
    <>
      <Wrapper
        variants={menuVariant}
        initial={false}
        animate={variants}
        transition={{ duration: 1, ease: easeOutQuad }}
      >
        <CloseButton onClick={() => setState({ menuOpen: false })} />
        <Content>
          {!isDesktopMode &&
            items.map((item, index) => (
              <MenuItem
                key={item.id}
                name={item.title}
                index={index}
                onClick={() => openPage(item.route)}
              />
            ))}
          {isDesktopMode && (
            <MenuContentDesktop openPage={openPage} items={items} />
          )}
        </Content>
      </Wrapper>
      <Overlay
        exit
        animate={variantOverlay}
        transition={{ duration: 1, ease: 'linear' }}
      />
    </>
  );
};

export default React.memo(MenuItems);
