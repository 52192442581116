import MenuButton from 'components/Menu/MenuButton';
import { FilmPageContext } from 'context/FilmPageContext';
import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { MenuItemType } from 'types/menuItems';
import { ACTIONS, getCategory, LABEL, Tracking } from 'utils/tracking';
import MenuItems from './MenuItems';

export type MenuButtonProps = {
  items: Array<MenuItemType>;
  light?: boolean;
};

const Menu = ({ items, light = false }: MenuButtonProps) => {
  const { pathname } = useRouter();
  const { state, setState } = useContext(FilmPageContext);

  useEffect(() => {
    if (process.browser) {
      if (state.menuOpen) {
        document.documentElement.style.overflow = 'hidden';
      } else if (state.preloaderExited) {
        document.documentElement.style.overflow = 'visible';
      }
    }
  }, [state.menuOpen]);

  const openMenu = () => {
    setState({ menuOpen: true });
    Tracking.event({
      category: getCategory(pathname),
      action: ACTIONS.Menu,
      label: LABEL.Menu,
    });
  };

  return (
    <>
      <MenuButton onClick={openMenu} light={light} />
      <MenuItems items={items} />
    </>
  );
};

export default React.memo(Menu);
