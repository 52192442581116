import styled from 'styled-components';
import { motion } from 'framer-motion';

export default styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary.light};
  z-index: 100;
  overflow: hidden;
`;
