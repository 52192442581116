import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { mediaDesktop, mediaTabletAndPortrait } from 'utils/responsive';
import theme from 'utils/theme';

export const Host = styled.footer`
  color: ${({ color }) => color};
  font-family: ${theme.fonts.arapey};
  font-style: italic;
  overflow: hidden;
  position: relative;
  ${({ sticky }) =>
    sticky &&
    css`
      position: absolute;
      bottom: 0;
      width: 100%;
      padding-bottom: 2vh;
    `};
`;

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 3vh;
  width: 100%;
  border-top: ${({ line, theme }) =>
    line ? `1px solid ${theme.colors.lightGray3}` : 'none'};
`;

export const Column = styled.div`
  align-items: center;
  display: flex;
`;

export const EmailLink = styled.button`
  display: inline-block;
  margin-right: 1.5vw;
  width: 4.5vmin;
  color: inherit;

  ${mediaTabletAndPortrait(css`
    width: 4vmin;
  `)}
  ${mediaDesktop(css`
    margin-right: 1vw;
    width: 1.5vw;
  `)}
`;

export const InstagramLink = styled.a`
  display: inline-block;
  margin-right: 3.5vw;
  width: 3vmin;
  ${mediaTabletAndPortrait(css`
    width: 2.6vmin;
  `)}
  ${mediaDesktop(css`
    margin-right: 1vw;
    width: 1.2vw;
  `)}
`;

export const Copyright = styled.span`
  font-size: 3.5vmin;
  /* text-transform: uppercase; */
  ${mediaTabletAndPortrait(css`
    font-size: 3vmin;
  `)}
  ${mediaDesktop(css`
    font-size: 1vw;
  `)}
`;

export const Label = styled.a`
  font-size: 2.85vmin;
  cursor: pointer;
  text-decoration: none;
  ${mediaTabletAndPortrait(css`
    font-size: 2.6vmin;
  `)}
  ${mediaDesktop(css`
    font-size: 1.1vw;
  `)}
`;

export const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-top: 3vh;
`;

export const ScrollButton = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 5vmin;
  height: 10vmax;
  cursor: pointer;
  padding-top: 2px;
  max-height: 101px;
  -webkit-tap-highlight-color: transparent;
  ${mediaDesktop(css`
    width: 1vw;
  `)};
`;
