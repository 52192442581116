import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import {
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export const MenuButtonWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  position: fixed;
  top: 7vh;
  right: 7%;
  z-index: 99;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  height: 20px;
  width: 42px;
  transform: translateY(-50%);
  ${mediaTablet(css`
    height: 22px;
    width: 53px;
  `)}
  ${mediaDesktop(css`
    height: 24px;
    width: 42.6px;
  `)}
`;

export default MenuButtonWrapper;
