import styled, { css } from 'styled-components';
import {
  landscape,
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export default styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 10%;
  height: 14vw;
  width: 14vw;
  max-height: 70px;
  max-width: 70px;
  ${landscape} {
    height: 6vw;
    width: 6vw;
    top: 15%;
  }
  ${mediaTablet(css`
    top: 10%;
  `)}
  ${mediaTabletAndPortrait(css`
    height: 8vw;
    width: 8vw;
  `)}
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: none;
  }
  ${mediaDesktop(css`
    top: 12%;
    height: 3.75vw;
    width: 3.75vw;
  `)}
`;
