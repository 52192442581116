import styled, { css } from 'styled-components';
import {
  landscape,
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export default styled.div`
  position: absolute;
  top: 20%;
  left: 0;
  display: flex;
  width: 100%;
  height: 75%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  ${landscape} {
    flex-direction: row;
    height: 100%;
    width: 96%;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  ${mediaTablet(css`
    height: 70%;
    top: 23%;
    left: 0;
    width: 100%;
    flex-direction: row;
    transform: translateY(0);
  `)}
  ${mediaTabletAndPortrait(css`
    flex-direction: column;
  `)}
  ${mediaDesktop(css`
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    position: relative;
    transform: translateY(0);
  `)}
`;
