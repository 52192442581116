import React from 'react';

const Email = ({ width = '100%', height = '100%' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5862 0.539062H2.41113L14.9986 13.1266L27.5862 0.539062Z"
        fill="currentColor"
      />
      <path
        d="M15.9799 15.9827L14.9979 16.9647L14.0159 15.9827L0 1.9668C0 2.05607 0 2.05607 0 2.14534V18.3931C0 19.2858 0.714185 20 1.60692 20H28.4781C29.3709 20 30.085 19.2858 30.085 18.3931V2.14534C30.085 2.05607 30.085 2.05607 30.085 1.9668L15.9799 15.9827Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Email;
