import React from 'react';

import Copy from 'components/Icons/Copy';

import { Text, Content, Wrapper, Arrow, CopyIcon } from './styled';

const CopyPopup = ({
  sticky,
  animate,
  variants,
  initial,
  fixed,
}): JSX.Element => {
  return (
    <Wrapper
      sticky={sticky}
      animate={animate}
      variants={variants}
      initial={initial}
      fixed={fixed}
    >
      <Content>
        <CopyIcon>
          <Copy />
        </CopyIcon>
        <Text>email copied to clipboard</Text>
      </Content>
      <Arrow />
    </Wrapper>
  );
};

export default CopyPopup;
