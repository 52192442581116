import MenuButtonWrapper from 'components/Menu/MenuButton/styled/MenuButtonWrapper';
import CallBackFunc from 'interface/callbackFunc';
import React from 'react';
import Bar from './styled/Bar';

interface Props {
  onClick?: CallBackFunc<void, void>;
  light?: boolean;
}

const MenuButton = ({ onClick, light = false }: Props) => (
  <MenuButtonWrapper onClick={onClick} type="button" aria-label="menu">
    <Bar light={light} />
    <Bar light={light} />
    <Bar light={light} />
  </MenuButtonWrapper>
);

export default MenuButton;
