import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const style = css`
  display: flex;
  width: 102%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
`;

export default styled.div`
  ${style}
`;

export const MotionItemWrapper = styled(motion.div)`
  ${style}
`;
