import CallBackFunc from 'interface/callbackFunc';
import React from 'react';
import { createTitle } from 'components/FilmPage/FilmPageHeadDesktop/utils';
import MenuItemWrapper from './styled/MenuItemWrapper';
import MenuItemText from './styled/MenuItemText';
import Number from './styled/Number';

type MenuItemProps = {
  index: number;
  name: string;
  onPointerEnter?: CallBackFunc<React.PointerEvent, void>;
  onPointerLeave?: CallBackFunc<void, void>;
  onClick?: CallBackFunc<void, void>;
};

const MenuItem = ({
  index,
  name,
  onPointerEnter,
  onPointerLeave,
  onClick,
}: MenuItemProps) => {
  return (
    <MenuItemWrapper
      index={index}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onClick={onClick}
      data-key={index}
    >
      <MenuItemText dangerouslySetInnerHTML={createTitle(name)} />
      <Number>0{index + 1}</Number>
    </MenuItemWrapper>
  );
};

export default MenuItem;
