import React from 'react';

const Instagram = ({ width = '100%', height = '100%' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.539062"
        y="0.539062"
        width="19.4615"
        height="19.4615"
        rx="7"
        fill="currentColor"
      />
      <circle
        cx="10.2697"
        cy="10.2697"
        r="4.86538"
        fill="#EEEEEE"
        stroke="#252525"
        strokeWidth="2"
      />
      <circle cx="15.5" cy="5.5" r="1.5" fill="white" />
    </svg>
  );
};

export default Instagram;
