export const wrap = (index: number, max: number): number =>
  ((index % max) + max) % max;

export const getWithWrap = (index: number, arr: Array<any>): any =>
  arr[wrap(index, arr.length)];

export const getRectCenter = (rect: DOMRect): { x: number; y: number } => {
  return { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 };
};

export const getCenterPointWithOffset = (
  rect: {
    x: number;
    y: number;
    width: number;
    height: number;
  },
  height: number,
  width: number
): { x: number; y: number } => {
  return {
    x: rect.x + (rect.width - width) / 2,
    y: rect.y + (rect.height - height) / 2,
  };
};
