import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { landscape, mediaDesktop, mediaTablet } from 'utils/responsive';
import { MetadataBold } from 'utils/styles/typography';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vw;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};

  ${mediaTablet(css`
    height: 4vw;
  `)};

  ${landscape} {
    height: 4.2vw;
  }

  ${mediaDesktop(css`
    height: 2vw;
  `)}
`;

export const Wrapper = styled(motion.div)`
  position: fixed;
  bottom: 20vh;

  width: 45vw;
  height: 10vh;
  z-index: 10;
  pointer-events: none;

  ${mediaTablet(css`
    width: 19vw;
    bottom: 13vh;
  `)};

  ${landscape} {
    bottom: 22vh;
  }

  ${mediaDesktop(css`
    width: 17vw;
    bottom: 16vh;
  `)}

  ${({ sticky }) =>
    sticky &&
    css`
      bottom: 4vh;

      ${mediaTablet(css`
        bottom: 5vh;
      `)};
      ${landscape} {
        bottom: 12vh;
      }

      ${mediaDesktop(css`
        width: 17vw;
        bottom: 6vh;
      `)}
    `};
`;

export const Text = styled(MetadataBold)`
  text-transform: uppercase;
  font-size: 1.7vw;
  margin: 1vw;

  ${mediaTablet(css`
    font-size: 0.9vw;
  `)};

  ${mediaDesktop(css`
    font-size: 0.75vw;
    margin: 0.5vw;
  `)}
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;

  border-right: 1.7vw solid transparent;
  border-bottom: 1.7vw solid transparent;
  border-top: 1.7vw solid black;
  border-left: 1.7vw solid black;

  ${mediaTablet(css`
    border-right: 1vw solid transparent;
    border-bottom: 1vw solid transparent;
    border-top: 1vw solid black;
    border-left: 1vw solid black;
  `)};

  ${mediaDesktop(css`
    border-right: 0.7vw solid transparent;
    border-bottom: 0.7vw solid transparent;
    border-top: 0.7vw solid black;
    border-left: 0.7vw solid black;
  `)};
`;

export const CopyIcon = styled.div`
  width: 3vw;

  ${mediaTablet(css`
    width: 1.5vw;
  `)};

  ${mediaDesktop(css`
    width: 1.2vw;
  `)};
`;
