import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import {
  landscape,
  mediaDesktop,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export default styled(motion.div)`
  position: absolute;
  width: 25vw;
  height: 1px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.lightGray};
  ${landscape} {
    width: 15vw;
  }
  ${mediaTabletAndPortrait(css`
    width: 20vw;
  `)}
  ${mediaDesktop(css`
    height: 0.2%;
    width: 7vw;
  `)}
`;
