import { motion } from 'framer-motion';
import styled from 'styled-components';

export default styled(motion.div)`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
