import { useEffect, useState } from 'react';
import useWindowSize from 'utils/hooks/useWindowSize';
import * as VARS from 'utils/responsive/vars';

const useDesktopMode = () => {
  const [isDesktopMode, setDesktopMode] = useState(true);
  const { height, width } = useWindowSize();

  useEffect(() => {
    setDesktopMode(
      width >= VARS.breakpointDesktop && height >= VARS.desktopMinHeight
    );
  }, [height, width]);

  return { isDesktopMode };
};

export default useDesktopMode;
