import styled, { css } from 'styled-components';

import media from 'utils/responsive';

export const FilmWrapper = styled.div`
  margin-top: ${({ hasMargin }) => (hasMargin ? '12vh' : 0)};
  overflow: hidden;
  width: 100%;

  ${media.tablet(css`
    margin-top: 0;
  `)}
`;

export const FooterWrapper = styled.div`
  width: 86%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export const FooterWrapperFixed = styled.div`
  width: 86%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;
