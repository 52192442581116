import styled from 'styled-components';

export default styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  clip-path: inset(0 100% 100% 0);
`;
