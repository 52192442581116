import styled from 'styled-components';

export default styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 40vw;
  height: auto;
  display: none;
`;
