import CallBackFunc from 'interface/callbackFunc';
import React from 'react';

interface Props {
  className?: string;
  onClick?: CallBackFunc<any, any>;
}

const ArrowUp = ({ className, onClick }: Props) => {
  return (
    <svg
      width="9"
      height="101"
      viewBox="0 0 9 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M9.00002 9.17745L4.50001 0L0 9.17745L3.54939 7.60869L3.54939 101H5.45062L5.45063 7.60869L9.00002 9.17745Z"
        fill="currentColor"
        shapeRendering="geometricPrecision"
      />
    </svg>
  );
};

export default ArrowUp;
