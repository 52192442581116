import useMenuVariants from 'components/Menu/MenuItems/useMenuVariants';
import CallBackFunc from 'interface/callbackFunc';
import React from 'react';
import CloseButtonWrapper from './styled/CloseButtonWrapper';
import Line from './styled/Line';
import CrossWrapper from './styled/CrossWrapper';
import Cross from './styled/Cross';

interface Props {
  onClick?: CallBackFunc<any, void>;
}

const animate = {
  rotate: 90,
  transition: { duration: 0.7 },
};

const lineVariants = {
  open: {
    x: '-50%',
    y: '-50%',
    scaleX: 1,
  },
  hidden: {
    x: '-50%',
    y: '-50%',
    scaleX: 0,
  },
};

const CloseButton = ({ onClick }: Props) => {
  const variants = useMenuVariants();

  return (
    <CloseButtonWrapper
      onClick={onClick}
      role={'button'}
      aria-label="close-menu"
    >
      <Line
        variants={lineVariants}
        initial={false}
        animate={variants}
        transition={{ delay: 0.8 }}
      />
      <CrossWrapper whileHover={animate} whileTap={animate}>
        <Cross />
      </CrossWrapper>
    </CloseButtonWrapper>
  );
};

export default CloseButton;
